import LazySizes from 'lazysizes'
import Alpine from 'alpinejs';
import GLightbox from 'glightbox'
import VenveoApplication from "./base/VenveoApplication"

// polyfills
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';

// Import our CSS
import "@/css/app.css";

window.Alpine = Alpine
window.lightbox = GLightbox({
    autoplayVideos: true,
    plyr: {
        config: {
            autoplay: true,
            controls: ['play-large', 'progress'],
            // iconUrl: 'https://localhost:8080/spritemap.svg',
            // iconPrefix: 'sprite'
        }
    }
})
window.GLightbox = GLightbox;

// Set background images lazily
document.addEventListener('lazybeforeunveil', function (e) {
    const bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});


import Headroom from './components/ui/Headroom'
import HeaderSearch from "./components/ui/HeaderSearch"
import MobileNav from './components/ui/MobileNav'
import SmoothAnchor from './components/ui/SmoothAnchor'
import ProjectGallerySlider from "./components/ui/sliders/ProjectGallerySlider";
import Accordion from"./components/ui/Accordion";
import PlyrHero from "./components/ui/PlyrHero";
import ReadMoreProse from "./components/ui/ReadMoreProse";
import TestimonialSlider from "./components/ui/sliders/TestimonialSlider";
import DropdownFilters from "./components/ui/DropdownFilters";
import MultipleProductSlider from "./components/ui/sliders/MultipleProductSlider";
import NewSlider from "./components/ui/sliders/NewSlider";

const headroom = new Headroom();
headroom.init();

const headerSearch = new HeaderSearch();
headerSearch.init();

const mobileNav = new MobileNav();
mobileNav.init();

const smoothScroll = new SmoothAnchor();
smoothScroll.init();

const accordion = new Accordion();
accordion.init();

const plyrHero = new PlyrHero();
plyrHero.init();

const readMore = new ReadMoreProse();
readMore.init();

const testimonialSlider = new TestimonialSlider()
testimonialSlider.init();

const dropdownFilters = new DropdownFilters();
dropdownFilters.init();

const projectGallerySlider = new ProjectGallerySlider();
projectGallerySlider.init();

const multipleProductSlider = new MultipleProductSlider();
multipleProductSlider.init();

const newSlider = new NewSlider();
newSlider.init();

LazySizes.init()
Alpine.start()