'use strict';

import Component from "../../base/Component";

export default class ReadMoreProse extends Component {
    constructor(props: object = {}) {
        super(props)
        this.rd = document.querySelectorAll('[data-readmore]')
    }

    init() {
        const triggerCallback = (event) => {
            const article = event.target.closest('article')
            article.classList.toggle('expanded')
        }

        if( this.rd.length > 0 ) {
            this.rd.forEach((readmore) => {
                const readmorebtn = readmore.querySelector('[data-readmore-toggle]')
                readmorebtn.addEventListener('click', triggerCallback);
            });
        }
    }
}
