import {
    Swiper,
    Navigation,
    Autoplay,
    A11y
} from 'swiper';

Swiper.use([Navigation, Autoplay, A11y]);

import Component from "../../../base/Component";

export default class ProjectGallerySlider extends Component {
    constructor(props: object = {}) {
        super(props)
    }

    init() {
        const swiperOptions = {
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };

        let testimonialEl = null;
        let testimonialSliderInstance = null;
        htmx.on('htmx:afterSettle', function() {
            testimonialEl = document.querySelector('[data-slide-project]');
            if (testimonialEl) {
                testimonialSliderInstance = new Swiper('[data-slide-project]', swiperOptions);
            }
        });
    }
}
