'use strict';

import Plyr from 'plyr';
import Component from "../../base/Component";

export default class PlyrHero extends Component {
    constructor(props: object = {}) {
        super(props)
    }

    init() {
        new Plyr('#plyrhero-video', {
            controls: [],
            autoplay: true,
            muted: true,
            loop: { active: true },
            ratio: false
            // iconUrl: '/assets/img/sprite.svg',
            // iconPrefix: 'icon'
        });
    }
}
