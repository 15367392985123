import {
    Swiper,
    Navigation,
    Autoplay,
    EffectFade,
    A11y
} from 'swiper';

Swiper.use([Navigation, Autoplay, EffectFade, A11y]);

import Component from "../../../base/Component";

export default class TestimonialSlider extends Component {
    constructor(props: object = {}) {
        super(props)
        this.testimonialEl = document.querySelector('[data-slide-testimonial]');

        this.SwiperOptions = {
            observer: true,
            touchRatio: 1,
            autoHeight: true,
            autoplay: {
                delay: 5000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.testimonial-slider__control-next',
                prevEl: '.testimonial-slider__control-prev',
            },
            // Responsive breakpoints
            breakpoints: { 
                // when window width is <= 1024px
                1024: {
                    touchRatio: 0,
                },
            }
        };
    }

    init() {
        // Init Each Swipers
        this.testimonialSlideInit = new Swiper(this.testimonialEl, this.SwiperOptions);
    }
}
