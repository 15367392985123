'use strict';

import SmoothScroll from 'smooth-scroll';
import Component from "../../base/Component";

export default class SmoothAnchor extends Component {
    constructor(props: object = {}) {
        super(props)
        this.headerHeight = document.querySelector('header').offsetHeight
    }

    init() {
        // All animations will take exactly 300ms
        new SmoothScroll('a[href*="#"]', {
            speed: 300,
            speedAsDuration: true,
            offset: this.headerHeight || 118
        });
    }
}