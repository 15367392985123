import Component from "../../base/Component";

export default class HeaderSearch extends Component {
    private headerSearchInput: HTMLElement|null;
    private headerSearchTrigger: NodeListOf<HTMLElement>|null;
    private header: HTMLElement|null;
    private activeSearchClass: string;
    constructor(props: object = {}) {
        super(props);
        this.headerSearchInput = null;
        this.headerSearchTrigger = null;
        this.activeSearchClass = 'search-active';
        this.header = document.querySelector('header');
    }

    init() {
        const triggerCallback = (event) => {
            if( !this.header.classList.contains(this.activeSearchClass) ) {
                return expandSearch();
            } else if ( this.header.classList.contains(this.activeSearchClass) ) {
                return hideSearch();
            }
        }
        
        const expandSearch = () => {
            this.header.classList.add(this.activeSearchClass);
            setTimeout(setInputFocus, 600);
        }
        
        const setInputFocus = () => {
            this.headerSearchInput.focus();
        }
        
        const hideSearch = () => {
            this.header.classList.remove(this.activeSearchClass);
            this.headerSearchInput.blur();
        }

         // Search input
         this.headerSearchInput = document.querySelector('[data-hs-input]');
         // Search trigger
         this.headerSearchTrigger = document.querySelectorAll('[data-hs-trigger]');
 
         this.headerSearchTrigger.forEach((trigger) => {
             trigger.addEventListener('click', triggerCallback);
         });
    }
}