import {
    Swiper,
    Navigation,
    Autoplay,
    A11y
} from 'swiper';

Swiper.use([Navigation, Autoplay, A11y]);

import Component from "../../../base/Component";

export default class MultipleProductSlider extends Component {
    sliderWrap = null;

    constructor(props: object = {}) {
        super(props)
        this.mpsContainerEl = document.querySelector('#multipleProductSlider');
        if (this.mpsContainerEl) {
            this.sliderWrap = this.mpsContainerEl.querySelectorAll('[data-mp-slider-wrap]');
        }
    }

    init() {
        if(this.sliderWrap && this.sliderWrap.length > 0) {
            // Instantiate Each Swipers
            this.sliderWrap.forEach((el, index) => {
                const sliderEl = el.querySelector('[data-mp-slider]')
                new Swiper(sliderEl, {
                    touchRatio: 1,
                    slidesPerView: 3,
                    direction: 'vertical',
                    observer: true,
                    observeParents: true,
                    observeSlideChildren: true,
                    navigation: {
                        nextEl: `.swiper-button-next-${index}`,
                        prevEl: `.swiper-button-prev-${index}`
                    },
                    // Responsive breakpoints
                    breakpoints: { 
                        // when window width is <= 1280px
                        1280: {
                            slidesPerView: 3,
                            direction: 'horizontal'
                        },
                        // when window width is <= 768px
                        768: {
                            slidesPerView: 2,
                            direction: 'horizontal'
                        },
                    }
                })
            });
        }
    }
}
