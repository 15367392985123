import Component from "../../base/Component";

export default class DropdownFilters extends Component {
    constructor(props: object = {}) {
        super(props);
    }

    init() {
        const dropdowns = document.querySelectorAll('select[data-dropdown-filter]')
        dropdowns.forEach((dropdown) => {
            dropdown.addEventListener('change', (event) => {
                const selectedOption = dropdown.options[dropdown.selectedIndex]
                if (selectedOption.dataset && selectedOption.dataset.dropdownUrl) {
                    window.location.href = selectedOption.dataset.dropdownUrl
                }
            })
        })
    }
}