import Accordion from "accordion/src/accordion.js"

import Component from "../../base/Component";

export default class Accordions extends Component {
    constructor(props: object = {}) {
        super(props);
        this.accordion = document.querySelectorAll('[data-accordion]');
    }

    init() {
        this.accordion.forEach((el) => {
            // This will tell us the type of accordion to create
            let type = el.dataset.accordion;
            if( type === 'ResourcesAccordion' ) {
                new Accordion.Accordion(el, {
                    noTransforms: true,
                    modal: true,
                })
            }
            // else if..
        })
    }
}