import {
    Swiper,
    Navigation,
    Autoplay,
    A11y
} from 'swiper';

Swiper.use([Navigation, Autoplay, A11y]);

import Component from "../../../base/Component";

export default class NewSlider extends Component {
    constructor(props: object = {}) {
        super(props)
        console.log(1234)

    }

    init() {
        const swiperOptions = {
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };

        if (document.querySelector('.new-swiper')) {
            const swiperSlider = new Swiper('.new-swiper', swiperOptions)
        }
        // let testimonialEl = null;
        // let testimonialSliderInstance = null;
        // htmx.on('htmx:afterSettle', function() {
        //     testimonialEl = document.querySelector('[data-new-slider]');
        //     if (testimonialEl) {
        //         testimonialSliderInstance = new Swiper('[data-new-slider]', swiperOptions);
        //     }
        // });
    }
}
